<template>
  <div class="side">
    <div class="side-title">系统配置</div>
    <div class="nav-list">
      <div
        class="nav-times"
        :class="navListIndex === item.title ? 'active' : ''"
        v-for="(item, index) in navList"
        :key="index"
        @click="changeNav(item.title)"
      >
        <img
          :src="navListIndex === item.title ? item.activeSrc : item.defaultSrc"
        />
        <span>{{ item.name }}</span>
      </div>
    </div>
    <img class="icon11" src="../assets/image/configure/icon11.png" />
  </div>
</template>

<script>
export default {
  name: "mySide",
  data() {
    return {
      navListIndex: 0,
      navList: [
        {
          name: "农场信息配置",
          title: "farm",
          defaultSrc: require("../assets/image/configure/icon4.png"),
          activeSrc: require("../assets/image/configure/icon3.png"),
        },

        {
          name: "品种分类",
          title: "cropType",
          defaultSrc: require("../assets/image/configure/icon7.png"),
          activeSrc: require("../assets/image/configure/icon8.png"),
        },
        {
          name: "农资分类",
          title: "assetType",
          defaultSrc: require("../assets/image/configure/icon6.png"),
          activeSrc: require("../assets/image/configure/icon5.png"),
        },
        {
          name: "流程模板配置",
          title: "processTemplate",
          defaultSrc: require("../assets/image/configure/icon2.png"),
          activeSrc: require("../assets/image/configure/icon1.png"),
        },
        {
          name: "流程配置",
          title: "process",
          defaultSrc: require("../assets/image/configure/icon10.png"),
          activeSrc: require("../assets/image/configure/icon9.png"),
        },
        {
          name: "个人资料",
          title: "datum",
          defaultSrc: require("../assets/image/configure/icon15.png"),
          activeSrc: require("../assets/image/configure/icon13.png"),
        },
        {
          name: "账号设置",
          title: "password",
          defaultSrc: require("../assets/image/configure/icon14.png"),
          activeSrc: require("../assets/image/configure/icon12.png"),
        },

        {
          name: "日志报表",
          title: "statement",
          defaultSrc: require("../assets/image/configure/statement2.png"),
          activeSrc: require("../assets/image/configure/statement1.png"),
        },
        {
          name: "系统日志",
          title: "log",
          defaultSrc: require("../assets/image/configure/log2.png"),
          activeSrc: require("../assets/image/configure/log1.png"),
        },
      ],
    };
  },
  watch: {
    "$route.name": {
      handler(newVal) {
        this.navListIndex = newVal;
      },
    },
  },
  created() {
    this.navListIndex = this.$route.meta.title;
  },
  methods: {
    changeNav(title) {
      this.navListIndex = title;
      this.$router.push({
        name: title,
      });
    },
  },
};
</script>

<style lang="less">
.side {
  width: 450px;
  min-height: 975px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #142d4c;
  padding: 19px 48px;
  margin-right: 14px;
  color: #fff;
  .side-title {
    width: 100%;
    line-height: 22px;
    height: 22px;
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
    padding-left: 11px;
    border-left: 4px solid #3e90e5;
    border-radius: 2px;
    margin-bottom: 27px;
  }
  .nav-list {
    width: 100%;
    flex: 1;
    .nav-times {
      width: 100%;
      height: 48px;
      background: #224d7f;
      border-radius: 3px;
      margin-bottom: 25px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.active {
        background-color: #3e90e5;
      }
      &:nth-of-type(1),
      &:nth-of-type(3),
      &:nth-of-type(5),
      &:nth-of-type(7) {
        margin-bottom: 53px;
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 19px;
      }
      span {
        min-width: 100px;
      }
    }
  }
  .icon11 {
    width: 364px;
  }
}
</style>
